import React, { useEffect, useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import '../lib/scss/header.scss'
import LogoBlack from '../lib/assets/logo_black.png'
import LogoWhite from '../lib/assets/logo_white.png'

const Header = () => {

  const [smallLogo, setSmallLogo] = useState(false);
  const [blackLogo, setBlackLogo] = useState(false);
  const [visible, setVisible] = useState(false);

  const Logo = (props) => {
    return (
      <img 
        className={`logo logo-white ${props.small ? 'small' : ''}`} 
        src={props.source} 
        alt="logo" />
    );
  }

  const handleScroll = () => {
    requestAnimationFrame(() => {
      const screenHeight = window.innerHeight;
      const scrollY = window.scrollY;

      // Scroll toggle positions
      const smallCeil = screenHeight * 0.05;
      const smallFloor = screenHeight * 0.85;
      const overContent = screenHeight * 0.97;
      
      if (scrollY > smallCeil && scrollY < smallFloor) {
        setSmallLogo(true);
      } else {
        setSmallLogo(false);
      }

      if (scrollY >= overContent) {
        setBlackLogo(true);
      } else {
        setBlackLogo(false);
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    setTimeout(() => {
      setVisible(true);
    }, 50);

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header className={
      `header ${blackLogo ? 'over-content' : ''} ${smallLogo ? 'small' : ''} ${visible ? 'visible' : ''}`
      }>
      <AnchorLink href='#top'>
        { !blackLogo && 
          <Logo source={LogoWhite} small={smallLogo} />
        }
        { blackLogo &&
          <Logo source={LogoBlack} small={smallLogo} />
        }
      </AnchorLink>
    </header>
  )
}

export default Header
