import React from 'react'

const ScrollIcon = props => (
  <svg width={40} height={40} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#00D6BE" cx={20} cy={20} r={20} />
      <path stroke="#FFF" strokeWidth={3} d="M9 15l11 11 11-11" />
    </g>
  </svg>
)

export default ScrollIcon
