import React from 'react'
import Data from './data/data'
import Loading from './components/loading'
import Content from './views/content'
import './App.css'


function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 3000))
}

class App extends React.Component {

  state = {
    loading: true,
    sku: `default`
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let sku = params.get(`sku`);

    if (sku) {
      this.setState({
        sku: sku.toLowerCase()
      })
    }

    // this simulates an async action, after which the component will render the content
    demoAsyncCall().then(() => this.setState({ loading: false }));
  };


  // Scroll to top on change
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render(){
    const { loading, sku } = this.state
    const title = Data.sku[sku].title
    const type = Data.sku[sku].type

    if (loading) {
      return (
        <Loading
          title={title}
        />
      );

    } else if (type) {
      return (
        <Content
          sku={sku}
          type={type}
        />
      );
    } else {
      return (
        <h1>default page</h1>
      );
    }
  }
}

export default App;
