import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import '../lib/scss/intro.scss'
import Data from '../data/data'
import ScrollIcon from './scroll-icon';

function Intro({sku}){

  const provenance = Data.sku[sku].provenance
  
  const Image = (props) => {
    const source = process.env.PUBLIC_URL + props.source;
    return <img className="image" src={source} alt={props.title} draggable="false" />
  }

  const ScrollLabel = (props) => {
    return props.type === 'health' ? 'Health Benefits' : 'Recipe Ideas';
  }

  return (
    <div className="intro">
      
      <div className="summary">
        <Image source={Data.sku[sku].image} title={Data.sku[sku].title} />
        <h2 className="title">{Data.sku[sku].title}</h2>
        <h3 className="subtitle">{Data.sku[sku].subTitle}</h3>
      </div>

      <div className="details">

        <dl className="list">
          
          <dt className="label">Origin</dt>
          <dd className="value">{provenance.origin}</dd>
        
          <dt className="label">Farmer</dt>
          <dd className="value">{provenance.farmer}</dd>
        
          <dt className="label">Picked On</dt>
          <dd className="value">{provenance.pickedOn}</dd>
        
        </dl>

        <AnchorLink href='#content' className="scroll-hint">
          <ScrollIcon className="scroll-icon" />
          <ScrollLabel type={Data.sku[sku].type} />
        </AnchorLink>

      </div>

    </div>
  )
}

export default Intro
