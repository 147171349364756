import React from 'react'
import Data from '../data/data'
import '../lib/scss/health.scss'

function HealthTemplate({sku}){

  const Image = (props) => {
    const source = process.env.PUBLIC_URL + props.source;
    return <img className="image" src={source} alt={props.title} draggable="false" />
  }

  return (
    <div className="health">

      <h3 className="title">Health Benefits</h3>
      
      <dl className="list">
        {Data.sku[sku].health.map((item, key) =>
          <dt className="item" key={key}>
            <Image source={item.img} title={item.fact} />
            {item.fact}
          </dt>
        )}
      </dl>

    </div>
  )
}


export default HealthTemplate
