import React from 'react'
import Fade from 'react-reveal/Fade'
import Header from '../components/header'
import Intro from '../components/intro'
import HealthTemplate from '../template/health'
import RecipeTemplate from '../template/recipe'
import Footer from '../components/footer'

function Content({type, sku}){

  const Template = (props) => {
    let template

    if (props.type === 'health') {
      template = <HealthTemplate sku={sku} />
    } else if (props.type === 'recipe') {
      template = <RecipeTemplate sku={sku} />
    }

    return (
      <div id="content">
        {template}
      </div>
    )
  }

  return (
    <div id="top">
      <Header />
      <Fade bottom distance='25%'>
        <Intro sku={sku} />
        <Template type={type} sku={sku} />
      </Fade>
      <Footer />
    </div>
  )
}

export default Content
