import React from 'react'
import SELogo from '../lib/assets/se_logo_white.png'
import '../lib/scss/footer.scss'

const Footer = () => {

    return (
      <footer className="footer">
        
        <p className="footer-copyright">
          © Copyright Sharp End Partnership Ltd.<br/>
        </p>
        
        <a href="https://wearesharpend.com/" className="poweredbySE">
          <span className="footer-poweredby">powered by</span> <img width="300" src={SELogo} alt="SE Logo" />
        </a>

      </footer>
    )
}

export default Footer
