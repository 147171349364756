import React from 'react'
import Data from '../data/data'
import '../lib/scss/recipe.scss'

function RecipeTemplate({sku}){

  const image = process.env.PUBLIC_URL + Data.sku[sku].recipe.image

  return (
    <div className="recipe">
      
      <h2 className="title">{Data.sku[sku].recipe.title}</h2>
      <p className="subtitle">{Data.sku[sku].recipe.subTitle}</p>

      <img className="image" src={image} alt="" />

      <div className="ingredients">
        <h3 className="section-title">The Ingredients</h3>
        <ul>
          {Data.sku[sku].recipe.ingredients.map((item, key) =>
            <li key={key}>{item}</li>
          )}
        </ul>
      </div>

      <div className="method">
        <h3 className="section-title">The Method</h3>
        <ol>
          {Data.sku[sku].recipe.method.map((item, key) =>
            <li key={key}>{item}</li>
          )}
        </ol>
      </div>
      
    </div>
  )
}

export default RecipeTemplate
