import React from 'react'
import Fade from 'react-reveal/Fade'
import '../lib/scss/loading.scss'
import Logo from '../lib/assets/logo_white.png'

const Loading = ({title}) => (
  <div className="loading">
    <div className="centred">
      <Fade>
        <img className="logo" src={Logo} alt="logo" draggable="false" />
        <div className="titles">
          <h3 className="title faded faded-1">{title}</h3>
          <h3 className="title faded faded-2">{title}</h3>
          <h3 className="title faded faded-3">{title}</h3>
          <h3 className="title faded faded-4">{title}</h3>
          <h3 className="title">{title}</h3>
          <h3 className="title faded faded-4">{title}</h3>
          <h3 className="title faded faded-3">{title}</h3>
          <h3 className="title faded faded-2">{title}</h3>
          <h3 className="title faded faded-1">{title}</h3>
        </div>
      </Fade>
    </div>
  </div>
)

export default Loading
