module.exports = {
  sku: {

    carrot: {
      type: 'health',
      title: 'Carrot',
      subTitle: 'Touchon Variety',
      image: 'images/carrot.png',
      provenance: {
        origin: 'Hertfordshire',
        farmer: 'Ian Little',
        pickedOn: '12 November 2019',
      },
      health: [
        {
          img: 'images/donut.png', 
          fact: 'Low in fat'
        },
        {
          img: 'images/virus.png', 
          fact: 'High in antioxidants to improve immune function'
        },
        {
          img: 'images/pill.png', 
          fact: 'Good source of Vitamin B, K and Potassium'
        },
        {
          img: 'images/eye.png', 
          fact: 'Also a great source of Vitamin A to see in the dark'
        }
      ]
    },

    pepper: {
      type: 'recipe',
      title: 'Red Pepper',
      subTitle: 'Vidi Variety',
      image: 'images/redpepper.png',
      provenance: {
        origin: 'Chile',
        farmer: 'Joaquin Diaz',
        pickedOn: '12 November 2019',
      },
      recipe: {
        title: 'Stuffed Peppers',
        subTitle: 'Cook something nice',
        image: 'images/stuffed-peppers.jpg',
        ingredients: [
          '4 Red Peppers',
          '1 Brown Onion, Diced',
          '1 Clove of Garlic, Finely Chopped',
          '100g Cooked Rice',
          '100g Black Beans',
          '1 Bunch of Coriander, Finely Chopped',
          '50g Cheddar Cheese, Grated',
          '10 Cherry Tomatoes, Halved',
          '1 Teaspoon Cajun Seasoning'
        ],
        method: [
          'Heat a frying pan to medium and turn the oven to 180˚C.',
          'When the pain is hot and add some oil and the onion and garlic.',
          'Once the onion is softened add the tomatoes. Press them with a spatula to break them apart.',
          'Add the beans, rice and the cajun seasoning. Mix everything to combine and leave for 10 minutes.',
          'Check the mix and season to taste. Take off the heat.',
          'Cut the tops off the red peppers, remove the seeds and add the rice mixture into the inside.',
          'Add the cheese to the tops of the peppers and place into the oven for 20 minutes.',
          'Check that the cheese is soft and melted and remove from the oven. Add coriander and enjoy!'
        ]
      }
    },

    apple: {
      type: 'health',
      title: 'Apple',
      subTitle: 'Gala Variety',
      image: 'images/apple.png',
      provenance: {
        origin: 'New Zealand',
        farmer: 'Mark Thompson',
        pickedOn: '12 November 2019',
      },
      health: [
        {
          img: 'images/brain.png', 
          fact: 'Improves neurological health'
        },
        {
          img: 'images/hospital.png', 
          fact: 'Reduces the risk of a stroke'
        },
        {
          img: 'images/candy.png', 
          fact: 'Reduces the risk of diabetes'
        },
        {
          img: 'images/fries.png', 
          fact: 'Lowers levels of bad cholesterol'
        }
      ]
    },

    tomato: {
      type: 'recipe',
      title: 'Tomato',
      subTitle: 'Alicante Variety',
      image: 'images/tomato.png',
      provenance: {
        origin: 'New Zealand',
        farmer: 'Mark Thompson',
        pickedOn: '12 November 2019',
      },
      recipe: {
        title: 'Tomato kachumber',
        subTitle: 'Cook something nice',
        image: 'images/tomato-kachumber.jpg',
        ingredients: [
          '4 tomatoes, chopped',
          '1 small red onion, chopped',
          'Juice ½ lemon',
          'Pinch of cayenne pepper',
          '½ tsp cumin seeds',
          'Handful coriander leaves, chopped',
          'Mint leaves (optional)',
          'Green chilli, sliced (optional)'
        ],
        method: [
          'Mix all the ingredients together in a bowl until well coated, and cover until needed. Scatter over mint and chilli, if you like, before serving.'
        ]
      }
    },

    squash: {
      type: 'recipe',
      title: 'Squash',
      subTitle: 'Butternut Variety',
      image: 'images/butternut.png',
      provenance: {
        origin: 'Hertforshire',
        farmer: 'Ian Little',
        pickedOn: '12 November 2019',
      },
      recipe: {
        title: 'Butternut squash soup with chilli & crème fraîche',
        subTitle: 'Cook something nice',
        image: 'images/squash-soup.jpg',
        ingredients: [
          '1 butternut squash, about 1kg, peeled and deseeded',
          '2 tbsp olive oil',
          '1 tbsp butter',
          '2 onions, diced',
          '1 garlic clove, thinly sliced',
          '2 mild red chillies, deseeded and finely chopped',
          '850ml hot vegetable stock',
          '4 tbsp crème fraîche, plus more to serve'
        ],
        method: [
          'Heat oven to 200C/180C fan/gas 6.',
          'Cut 1 peeled and deseeded butternut squash into large cubes, about 4cm/1½in across, then toss in a large roasting tin with 1 tbsp of the olive oil.',
          'Roast for 30 mins, turning once during cooking, until golden and soft.',
          'While the butternut squash cooks, melt 1 tbsp butter with the remaining 1 tbsp olive oil in a large saucepan, then add 2 diced onions, 1 thinly sliced garlic clove and ¾ of the 2 deseeded and finely chopped red chillies. ',
          'Cover and cook on a very low heat for 15-20 mins until the onions are completely soft.',
          'Tip the butternut squash into the pan, add 850ml hot vegetable stock and 4 tbsp crème fraîche, then whizz with a stick blender until smooth. For a really silky soup, put the soup into a liquidiser and blitz it in batches.',
          'Return to the pan, gently reheat, then season to taste.',
          'Serve the soup in bowls with swirls of crème fraîche and a scattering of the remaining chopped chilli.'
        ]
      }
    },

    default: {
      title: 'Default',
      subTitle: 'Vidi Variety',
      image: '',
      provenance: {
        origin: 'Chile',
        farmer: 'Joaquin Diaz',
        pickedOn: '19 October 2019',
      },
      health: {
        fact1: 'Low in fat',
        antioxidants: 'antioxidants',
      }
    },
  }
}
